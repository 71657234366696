import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/joeloliveira/Development/WWW/Projects/notificare-website/src/components/layout.js";
import Seo from "../../components/seo";
import HeroPages from "../../components/heroes/hero-pages";
import { Container } from 'react-bootstrap';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={props.pageContext.frontmatter.title} pathname={props.location.pathname} mdxType="Seo" />
    <HeroPages content={{
      title: props.pageContext.frontmatter.title
    }} theme="primary" mdxType="HeroPages" />
    <div className="mdx-page-wrapper">
      <Container mdxType="Container">
        <p>{`This Cookie Policy of Notificare explains in which way cookies are stored on and information is read from your computer, tablet and/or mobile phone (hereafter: “Terminal Equipment”) through the websites and web applications of Notificare. This Cookie Policy also states for which purposes the collected information will be used. We thus advise you to read this Cookie Policy carefully.`}</p>
        <h2>{`What are Cookies?`}</h2>
        <p>{`Cookies are small (text) files that are sent to your browser while you are visiting the website or using the service of Notificare and are subsequently stored on the hard drive or in the memory of your Terminal Equipment. At your next visit to the website, the browser can send back this information. The cookies that are stored through this website cannot harm your Terminal Equipment or any files that are saved on your Terminal Equipment.`}</p>
        <h3>{`Strict Cookies`}</h3>
        <p>{`This paragraph applies to those cookies that are stored and read by Notificare itself or software we use to build this website. These so-called `}<strong parentName="p">{`Strict Cookies`}</strong>{` do not store any personal information.`}</p>
        <p>{`You will not be able to opt-out from these cookies as they are fundamental for this website to work. For instance, technical cookies allow us to use software hosted in an CDN (Content Delivery Network) or allow you to proceed through different pages by storing necessary preferences like `}<strong parentName="p">{`language`}</strong>{` and `}<strong parentName="p">{`preferred currency`}</strong>{` allowing us to remember those preferences in (order)forms that you can use throughout our website.`}</p>
        <p>{`These cookies enable Notificare to adjust the website to your specific preferences. Since your preferences are already set, your next visit will be less time-consuming and more agreeable.`}</p>
        <h4>{`Strict Cookies Providers`}</h4>
        <ul>
          <li parentName="ul">{`Notificare`}</li>
          <li parentName="ul">{`Cloudflare`}</li>
          <li parentName="ul">{`Google APIs`}</li>
        </ul>
        <h3>{`Functional Cookies`}</h3>
        <p>{`Apart from cookies that are strict for this website to work, some software we use in this website, from third parties, can store cookies on your Terminal Equipment throughout our website. This paragraph applies to these third party cookies that provide functionality in our website. Notificare can not control or influence the use of these cookies and/or the use by third parties of the information collected through these cookies. Notificare only provides the platform that enables storing these third party cookies. Notificare emphasizes that on the use of the data collected through these cookies further conditions of third parties may be applicable.`}</p>
        <p>{`The different types of functional cookies are not mandatory and this website can operate without them, therefore, you are allowed to opt-in or opt-out at any time.`}</p>
        <h4>{`Functional Cookies Providers`}</h4>
        <ul>
          <li parentName="ul">{`Atlassian Statuspage`}</li>
        </ul>
        <h3>{`Performance Cookies`}</h3>
        <p>{`In order to collect data on the usage of the website and visits to the website, Notificare uses third party software. These providers store a permanent cookie on your Terminal Equipment which is subsequently used to register your use of the website. This data is then analyzed by these third parties and the results are presented to Notificare. This enables Notificare to get insights in the way in which the website is used and, based on this information, to make adjustments to the website or the provided services.`}</p>
        <p>{`This kind of software can give this information to other third parties if it is legally obligated to do so and/or if those third parties are processing the information on behalf of these providers. These providers can not use the information for any other purpose. You, as a user, can opt-in or opt-out from these cookies`}</p>
        <h4>{`Performance Cookies Providers`}</h4>
        <ul>
          <li parentName="ul">{`Google Analytics`}</li>
        </ul>
        <h3>{`Marketing Cookies`}</h3>
        <p>{`These kind of cookies allow us to retarget our website's users, whenever they browse any website within the ads network. This is used to market our products and services when users are searching or visiting other websites by displaying an advert for specific Notificare's products.`}</p>
        <p>{`The different types of marketing cookies are not mandatory and this website can operate without them, therefore, you are allowed to opt-in or opt-out at any time.`}</p>
        <h4>{`Marketing Cookies Providers`}</h4>
        <ul>
          <li parentName="ul">{`Google AdWords`}</li>
        </ul>
        <h3>{`Deleting cookies`}</h3>
        <p>{`For your commodity we provide functionality (via the Cookie Settings manager found in the bottom of our pages) that enables you to opt-in or opt-out from some of these cookies whenever you want. Additional all these cookies can be deleted at any time by using the settings capabilities of your browser to disable cookies and/or to remove all cookies from your browser.`}</p>
        <p>{`By clicking the links below, you can read how you can delete the different types of cookies from different browsers.`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://support.google.com/chrome/bin/answer.py?hl=nl&answer=95647"
            }}>{`Chrome`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
            }}>{`Firefox`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://windows.microsoft.com/nl-NL/windows7/Change-Internet-Explorer-9-Privacy-settings"
            }}>{`Internet Explorer`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://support.apple.com/kb/PH5042"
            }}>{`Safari`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://help.opera.com/en/latest/web-preferences/#cookies"
            }}>{`Opera`}</a></li>
        </ul>
        <p>{`Please take into account that deleting cookies may disable some functionality in this website.`}</p>
        <h3>{`Privacy Policy`}</h3>
        <p>{`It is possible that information collected through a cookie or obtained otherwise, contains personal data. If this is the case, the Privacy Policy of Notificare is applicable on the processing of these data. The Privacy Policy can be read `}<a parentName="p" {...{
            "href": "/privacy"
          }}>{`here`}</a>{`.`}</p>
        <h3>{`Changes`}</h3>
        <p>{`This Cookie Policy can be changed at all times. Changes in the Cookie Policy will be announced on the website.`}</p>
        <h3>{`Questions`}</h3>
        <p>{`If you have any further questions about this Cookie Policy, you can send an e-mail to `}<a parentName="p" {...{
            "href": "mailto:support@notifica.re"
          }}>{`support@notifica.re`}</a>{`. You can also find additional information on the use of cookies on the website of the Dutch consumers' association.`}</p>
        <p><strong parentName="p">{`Last modified: 25 May 2018`}</strong></p>
      </Container>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      